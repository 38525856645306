import { useState, useRef } from "react";
import { DataFunctionalityProperties } from "./IDataFunctionalityProperties";
import { checkForExistingConsent } from "./checkForExistingConsent";
import { parseUserCookies } from "./parseUserCookies";
import { runPixel } from "./runPixel";
import { setFirstPartyCookie } from "./setFirstPartyCookie";
import { sendDataAndRedirect } from "./sendDataAndRedirect";

const DataFunctionality = (Properties: DataFunctionalityProperties) => {

    const existingConsentHasBeenChecked = useRef(false);
    const databaseExecutionHasBeenCalled = useRef(false);

    const [newConsent, setNewConsent] = useState(false);
    const [userHasChosen, setUserChoiceState] = useState(false);

    if (!existingConsentHasBeenChecked.current) {

        existingConsentHasBeenChecked.current = true;

        const existingCookiesToCheck = parseUserCookies(document.cookie);
        const existingConsent = checkForExistingConsent(existingCookiesToCheck, Properties.cookieName);

        if (existingConsent && !databaseExecutionHasBeenCalled.current) {
            databaseExecutionHasBeenCalled.current = true;
            runPixel(Properties.pixel);

            sendDataAndRedirect(true);
        };
    };

    if (newConsent && !databaseExecutionHasBeenCalled.current) {

        databaseExecutionHasBeenCalled.current = true;

        setFirstPartyCookie();
        runPixel(Properties.pixel);

        sendDataAndRedirect(true);
    };

    if (userHasChosen && !databaseExecutionHasBeenCalled.current) {
        databaseExecutionHasBeenCalled.current = true;
        if (!newConsent) {

            sendDataAndRedirect(false);
        };
    };

    return ({
        setNewConsent,
        setUserChoiceState,
        userHasChosen
    });
};

export { DataFunctionality };