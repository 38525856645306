const parseUserCookies = (rawCookies: string): {cookieName: string, cookieValue: string}[] => {
    const splitCookies = rawCookies.split(';');
    const cookies = [];
    
    for(let i = 0; i < splitCookies.length; i++) {
        const tempTwiceSplitCookie = splitCookies[i].split('=');
        cookies.push({cookieName: tempTwiceSplitCookie[0].trimStart(), cookieValue: tempTwiceSplitCookie[1]});
    }
    return cookies;
};

export { parseUserCookies };