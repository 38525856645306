import './DataConsentDetailsPage.css'

import { useNavigate } from 'react-router-dom';

const DataConsentDetailsPage: React.FC = () => {

    const navigate = useNavigate();
    const HCGoToMainPage = () => {

        navigate(`/${window.location.search}`);
    };

    return (
        <div className="DataConsentDetailsPage">

            <div className="SecondaryTitle">

                <h2>Privacy policy in short:</h2>

                <div className="DescriptionContainer">
                    <br />
                    <p className='titleDescription'>
                        View the full privacy policy {" "}
                        <a href={"https://www.intrahelsinki.com/privacy-policy"} className="LearnMoreLink">here</a>
                        .
                    </p>
                    <br />
                    <p className='titleDescription'>
                        You can withdraw your consent at any time {" "}
                        <a href={"https://www.intrahelsinki.com/data-requests/withdraw-consent"} className="LearnMoreLink">here</a>
                        .
                    </p>
                </div>

            </div>

            <div className='TextContentContainer'>

                <div className='textElement'>
                    <p className='textContentHeader'>
                        Data collection:
                    </p>

                    <p className='textContent'>
                        We gather data, use cookies and similar technologies to ensure the proper functioning of our website and services. This helps us deliver the content you're looking for and provide a seamless user experience. Some of the data we collect includes your IP address and device information.
                    </p>
                </div>

                <div className='textElement'>
                    <p className='textContentHeader'>
                        Who we share your data with:
                    </p>

                    <p className='textContent'>
                        By consenting to our privacy policy, you allow us to enhance our website and services and to serve relevant content and advertisements. Limited aspects of the collected data may be shared with third parties and advertising partners for advertising purposes.
                    </p>
                </div>

                <div className='textElement'>
                    <p className='textContentHeader'>
                        Further information:
                    </p>

                    <p className='textContent'>
                        For more information about how we collect data, how we use cookies, your data protection rights and how we use data please see the full privacy policy.
                        View the full privacy policy {" "}
                        <a href={"https://www.intrahelsinki.com/privacy-policy"} className="LearnMoreLink">here</a>
                        .
                    </p>
                </div>
            </div>

            <div className="CTAContainer">

                <button className="CTAButton" onClick={HCGoToMainPage}>
                    Go back
                </button>

            </div>

        </div>
    );
};

export default DataConsentDetailsPage