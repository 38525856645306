import './style/StyleVariables.css';
import './style/GlobalStyle.css'

import Footer from './components/Footer/Footer';
import PageWrapper from './components/PageWrapper/PageWrapper';
import DataConsentDetailsPage from './components/DataConsentDetailsPage/DataConsentDetailsPage';
import ConsentPage from './components/ConsentPage/ConsentPage'

import { Route, BrowserRouter } from "react-router-dom";
import { Routes } from "react-router";

import { DataFunctionality } from './components/DataFunctionality/DataFunctionality';
import { LoadingScreen } from './components/LoadingScreen/LoadingScreen';

interface consentPageTextContent {
  headerOne: string,
  headerTwo: string,
  smallPrint: string,
  learnMoreLink: string,
  consentAgreeText: string,
  consentDisagreeText: string
}

interface outgoingLinkItem {
  name: string,
  link: string,
  order: number,
  isOnNavbar: boolean,
  isOnFooter: boolean
}

const App: React.FC<{ consentPageTextContent: consentPageTextContent; outgoingLinks: outgoingLinkItem[] }> = (properties) => {

  const { consentPageTextContent, outgoingLinks } = properties;

  const DataFunctionalityProperties = {
    cookieName: "iha",
    pixel: "370277971558558"
  };

  const dataFunctionality: { setNewConsent: Function, setUserChoiceState: Function, userHasChosen: boolean } = DataFunctionality(DataFunctionalityProperties);

  const ConsentPageProperties = {
    ...consentPageTextContent,
    setNewConsent: dataFunctionality.setNewConsent,
    setUserChoiceState: dataFunctionality.setUserChoiceState,
    userHasChosen: dataFunctionality.userHasChosen
  };

  return (
    <>
      <BrowserRouter>
        <PageWrapper>
          {LoadingScreen(ConsentPageProperties.userHasChosen)}
          <Routes>
            <Route path="/" element={<ConsentPage {...ConsentPageProperties} />} />
            <Route path="/details" element={<DataConsentDetailsPage />} />
          </Routes>
          <Footer outgoingLinks={outgoingLinks} />
        </PageWrapper>
      </BrowserRouter>
    </>
  );
};

export default App;