import { ReactElement, useState, useEffect } from "react";
import './LoadingScreen.css'

const LoadingScreen = (currentlyLoading: boolean): ReactElement | null => {

    const [dots, setDots] = useState('');

    useEffect(() => {
        if (currentlyLoading) {
            // Start fading in and animating dots
            setDots('');

            const interval = setInterval(() => {
                setDots((prevDots) => {
                    return prevDots === '...' ? '' : prevDots + '.';
                });
            }, 500);

            return () => {
                clearInterval(interval);
            };
        }
    }, [currentlyLoading]);

    if(currentlyLoading) {

        return (
            <div className={`loadingContainer ${currentlyLoading ? 'fade-in' : 'fade-out'}`}>
                {currentlyLoading && (
                    <h2 className="secondaryTitle">
                        Loading{dots}
                    </h2>
                )}
            </div>
        );
    };
    
    return null;
};

export { LoadingScreen };
