import { Link } from "react-router-dom"
import './ConsentPage.css';

interface Props {
  headerOne: string,
  headerTwo: string,
  smallPrint: string,
  consentAgreeText: string,
  consentDisagreeText: string,
  setNewConsent: Function,
  setUserChoiceState: Function
};

const ConsentPage: React.FC<Props> = (Props) => {

  const userAccept: React.MouseEventHandler<HTMLButtonElement> = () => {
    Props.setNewConsent(true);
    Props.setUserChoiceState(true);
  };

  const userNoAccept: React.MouseEventHandler<HTMLButtonElement> = () => {
    Props.setUserChoiceState(true);
  };

  return (

    <div className="ConsentPage">

      <div className="Titles">

        <div className="MainTitle">
          <h1>{Props.headerOne}</h1>
        </div>

        <div className="SecondaryTitle">
          <h2>{Props.headerTwo}</h2>
        </div>
      </div>

      <div className="CTAContainer">

        <div className="DescriptionContainer">
          <p className='SmallPrint'>
            {Props.smallPrint + " "}
            <Link to={`/details${window.location.search}`} className="LearnMoreLink">Learn more</Link>
          </p>
        </div>

        <div className="ButtonContainer">
          <button className="CTAButton" id="accept" onClick={userAccept}>
            {Props.consentAgreeText}
          </button>

          <button className='CTAButton' id="noaccept" onClick={userNoAccept}>
            {Props.consentDisagreeText}
          </button>
        </div>

      </div>

    </div>
  );
};

export default ConsentPage;