import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

import outgoingLinks from './pageContent/ougoingLinks.json';
import textContent from './pageContent/textContent.json';
import callToAction from './pageContent/callToAction.json';

  const ConsentPageTextContent = {
    ...textContent,
    ...callToAction
  }


const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App consentPageTextContent={ConsentPageTextContent} outgoingLinks={outgoingLinks}/>
  </React.StrictMode>,
);