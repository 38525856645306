import { requestData } from './IRequestData';
import { parseUserCookies } from './parseUserCookies';

interface instanceData {
    consent: requestData;
    noconsent: requestData;
    redirectData: {
        url: string;
        uri: string;
    };
};

const sendInstanceDataEndpoint = "https://playlist-service-data-layer-qvouk2z6kq-ew.a.run.app";

const waitForFbp = async (consent: boolean): Promise<void> => {

    if(consent) {

        const cookieCheckInterval = 50;
        const maxChecks = 100;
    
        const delay = (ms: number): Promise<void> => {
    
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        };
    
        for(let i = 0; i < maxChecks; i++) {
    
            const foundCookies = parseUserCookies(document.cookie);
    
            for(let l = 0; l < foundCookies.length; l++) {
    
                const cookieToCheck = foundCookies[l];
    
                if(cookieToCheck.cookieName === "_fbp") {
    
                    return;
                };
            };
    
            await delay(cookieCheckInterval);
        };
    };

    return;
};

const getInstanceData = async (): Promise<instanceData> => {

    const instanceData = await fetch(`/serviceValues${window.location.search}`)
        .then(res => res.json())
        .then(data => {
            return data as instanceData;
        });

    if (!instanceData.redirectData.uri || !instanceData.redirectData.url) {

        throw new Error();
    }
    else {

        return instanceData;
    };
};

const sendInstanceData = async (dataToSend: requestData): Promise<void> => {

    const fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
    };

    for (let i = 0; i < 5; i++) {

        try {

            await fetch(sendInstanceDataEndpoint, fetchOptions);
            break;
        }
        catch (e) {


        };
    };
};

const redirectToExternalWebsite = (redirectData: { uri?: string, url?: string }) => {

    if(redirectData !== undefined && redirectData.uri !== undefined) {

        window.location.href = redirectData.uri;
    };
    if(redirectData !== undefined && redirectData.url !== undefined) {

        window.location.href = redirectData.url;
    };
    if(redirectData === undefined || (redirectData.uri === undefined && redirectData.url === undefined)) {

        window.location.href = 'https://www.intrahelsinki.com/service/playlist-not-found'
    };
};

const sendDataAndRedirect = async (consent: boolean) => {

    try {

        await waitForFbp(consent);

        const instanceData = await getInstanceData();

        if (consent) {

            await sendInstanceData(instanceData.consent);
        }
        else {

            await sendInstanceData(instanceData.noconsent);
        };

        redirectToExternalWebsite(instanceData.redirectData);
    }
    catch(e) {
        
        redirectToExternalWebsite({uri: undefined, url: undefined});
    };

};

export { sendDataAndRedirect };