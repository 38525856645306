const checkForExistingConsent = (cookies: Array<{ cookieName: string; cookieValue: string }>, cookieToCheck: string): boolean => {
    
    const matchingCookie = cookies.some((cookie) => {

        return cookie.cookieName === cookieToCheck
    });
    
    return matchingCookie;
  };

  export { checkForExistingConsent };