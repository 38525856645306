const setFirstPartyCookie = () => {

    const setTime = Date.now();
    const expiresIn = new Date(setTime + 31536000000);
    
    const cookieToSet = `iha=iha.${setTime}.${Math.floor(Math.random() * 1000000000)}; expires=${expiresIn.toUTCString()}; path=/;`;
    //const cookieToSet = `iha=iha.${setTime}.${Math.floor(Math.random() * 1000000000)}; expires=${expiresIn.toUTCString()}; path=/; domain=.intrahelsinki.com`;
    document.cookie = cookieToSet;
};

export { setFirstPartyCookie };